
































import { Component, Vue, Prop } from 'vue-property-decorator';
import { AdvertiserListingData } from '../domain/AdvertiserListingData';

@Component
export default class AdvertiserTable extends Vue {
    @Prop() private readonly advertisers!: AdvertiserListingData[];
    @Prop() private readonly pageCount!: number;
    @Prop() private readonly isLoading!: boolean;

    private name: string | null = null;

    private onPageChange(newPage: number) {
        this.$emit('pageChange', newPage - 1); // Lors d'un click sur la page 1, on doit retourner la page 0
    }

    private search() {
        this.$emit('updateSearch', this.name);
    }

    private editAdvertiser(advertiser: AdvertiserListingData) {
        this.$router.push(`/view-advertiser/${advertiser.id}`);
    }
}
