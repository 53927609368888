









import { Component, Vue, Watch } from 'vue-property-decorator';
import Card from '@/components/card/Card.vue';
import Page from '@/components/Page.vue';
import AdvertiserTable from '../components/AdvertiserTable.vue';
import {AdvertiserListingData} from '../domain/AdvertiserListingData';
import {AdvertiserService} from '../services/AdvertiserService';
import {AdvertiserSearchCriteria} from '../domain/AdvertiserSearchCriteria';
import ListingAddButton from '@/components/ListingAddButton.vue';

@Component({
    components: {Page, Card, AdvertiserTable, ListingAddButton},
})
export default class AdvertiserListing extends Vue {
    private isLoading: boolean = false;
    private advertisers: AdvertiserListingData[] = [];
    private pageCount: number = 1;
    private advertiserSearchCriteria: AdvertiserSearchCriteria = { page : 0, name: '' };

    private async mounted() {
        await this.fetchAdvertisers();
    }

    private async fetchAdvertisers() {
        this.isLoading = true;
        try {
            const { advertisers, pageCount } = await AdvertiserService
                .getAdvertisersWith(this.advertiserSearchCriteria, this.$store.getters['sessionStore/token']);

            this.advertisers = advertisers;
            this.pageCount = pageCount;
        } catch {
            this.$notify.error({
                title: 'ERREUR',
                message: 'Vos annonceurs n\'ont pas pu être chargés. Veuillez réessayer.',
            });
        }
        this.isLoading = false;
    }

    private async onSearchCriteriaUpdate(name: string) {
        this.advertiserSearchCriteria.page = 0;
        this.advertiserSearchCriteria.name = name;
        await this.fetchAdvertisers();
    }

    private async onPageChange(page: number) {
        this.advertiserSearchCriteria.page = page;
        await this.fetchAdvertisers();
    }
}
