import { render, staticRenderFns } from "./AdvertiserTable.vue?vue&type=template&id=69282ae2&scoped=true&"
import script from "./AdvertiserTable.vue?vue&type=script&lang=ts&"
export * from "./AdvertiserTable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69282ae2",
  null
  
)

export default component.exports